import React, { useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { Container } from '@mui/material';
import AppBarComponent from './AppBarComponent';
import MessageListComponent from './MessageListComponent';
import InputBarComponent from './InputBarComponent';
import SettingsPanelComponent from './SettingsPanelComponent';

const BACKGROUND_COLOR = '#f0f0f0'; // Adjust the color as necessary

// const WS_BACKEND = 'ws://localhost:3000/ws'
const WS_BACKEND = 'wss://ws.postman-echo.com/raw'

function ChatPage() {
    const [messageHistory, setMessageHistory] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [settingsOpen, setSettingsOpen] = useState(false);


    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(WS_BACKEND, {
        shouldReconnect: (closeEvent) => true,
    });

    React.useEffect(() => {
        if (lastJsonMessage !== null) {
            lastJsonMessage["sender"] = "Echo"
            setMessageHistory((prev) => [...prev, lastJsonMessage]);
        }
    }, [lastJsonMessage]);

    const handleSendMessage = () => {
        const messageObject = { sender: "Me", type: "text", content: inputMessage };
        sendJsonMessage(messageObject);
        setMessageHistory((prev) => [...prev, messageObject]);
        setInputMessage("");
    };
    

    const toggleDrawer = (open) => () => {
        setSettingsOpen(open);
    };

    return (
        <div style={{ backgroundColor: BACKGROUND_COLOR, minHeight: '100vh' }}>
            <AppBarComponent readyState={readyState} toggleDrawer={toggleDrawer} />
            <Container sx={{ paddingLeft: '0px', paddingRight: '0px', marginBottom: '90px' }}>
                <MessageListComponent messageHistory={messageHistory} />
            </Container>
            <InputBarComponent
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
                handleSendMessage={handleSendMessage}
            />
            <SettingsPanelComponent open={settingsOpen} toggleDrawer={toggleDrawer} />
        </div>
    );
}

export default ChatPage;
