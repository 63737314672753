import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/Error';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SettingsIcon from '@mui/icons-material/Settings';
import { ReadyState } from 'react-use-websocket';
import { keyframes } from '@mui/system';

const APP_BAR_COLOR = '#a8d5e2';

const spinAndStop = keyframes`
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(360deg);
  }
  90% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(720deg);
  }
`;

const Logo = styled('img')(({ theme }) => ({
  height: '40px',
  marginRight: '16px',
  animation: `${spinAndStop} 20s linear infinite`,
}));

function AppBarComponent({ readyState, toggleDrawer }) {
  return (
    <AppBar position="static" style={{ backgroundColor: APP_BAR_COLOR }}>
      <Toolbar>
        <Logo src="/veri.svg" alt="Veri Logo" />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Chat Application
        </Typography>
        {readyState === ReadyState.CONNECTING && (
          <IconButton>
            <AutorenewIcon sx={{ animation: 'rotation 1s infinite linear' }} color="warning" />
          </IconButton>
        )}
        {readyState === ReadyState.CLOSED && <IconButton><ErrorIcon color="error" /></IconButton>}
        <IconButton onClick={toggleDrawer(true)}>
          <SettingsIcon color="inherit" />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default AppBarComponent;

