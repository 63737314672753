import React from 'react';
import { Button, Drawer } from '@mui/material';

const LOGOUT_URL = 'https://yourapp.com/logout';

function SettingsPanelComponent({ open, toggleDrawer }) {
  const handleLogout = () => {
    window.location.href = LOGOUT_URL;
  };

  return (
    <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
      <div
        role="presentation"
        onClick={toggleDrawer(true)}
        onKeyDown={toggleDrawer(true)}
        style={{ width: 250, padding: 20 }}
      >
        <Button variant="text" color="primary" onClick={handleLogout} style={{ margin: '10px 0' }}>
          Logout
        </Button>
      </div>
    </Drawer>
  );
}

export default SettingsPanelComponent;
