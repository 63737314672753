import React from 'react';
import { TextField, IconButton, Grid, Input, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { styled } from '@mui/material/styles';

const APP_BAR_COLOR = '#a8d5e2';

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderRadius: '25px',
  '& fieldset': {
    borderRadius: '25px',
  },
}));

function InputBarComponent({ inputMessage, setInputMessage, handleSendMessage }) {
  return (
    <Grid container spacing={1} position="fixed" bottom={0} padding={2} style={{ backgroundColor: '#f8f9fa', borderTop: '1px solid #dee2e6', justifyContent: 'center' }}>
      <Grid item xs={11} sm={9} md={8}>
        <StyledTextField
          variant="outlined"
          fullWidth
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          multiline
          maxRows={4}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <label htmlFor="upload-button">
                  <Input accept="image/*" id="upload-button" type="file" style={{ display: 'none' }} />
                  <IconButton color="primary" component="span">
                    <AttachFileIcon />
                  </IconButton>
                </label>
                <IconButton color="primary" onClick={handleSendMessage} disabled={!inputMessage} sx={{ backgroundColor: inputMessage ? APP_BAR_COLOR : 'transparent' }}>
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}

export default InputBarComponent;
