import React, { useEffect, useRef } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

const MY_MESSAGE_BACKGROUND_COLOR = '#cce7ff';
const MY_MESSAGE_TEXT_COLOR = '#000';
const THEIR_MESSAGE_BACKGROUND_COLOR = '#e0e0e0';

const MyMessage = styled(ListItemText)(({ theme }) => ({
  backgroundColor: MY_MESSAGE_BACKGROUND_COLOR,
  borderRadius: '10px',
  padding: '10px',
  color: MY_MESSAGE_TEXT_COLOR,
  maxWidth: '70%',
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
}));

const TheirMessage = styled(ListItemText)(({ theme }) => ({
  backgroundColor: THEIR_MESSAGE_BACKGROUND_COLOR,
  borderRadius: '10px',
  padding: '10px',
  maxWidth: '70%',
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
}));

function MessageListComponent({ messageHistory }) {
  const endOfMessagesRef = useRef(null);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messageHistory]);

  return (
    <List sx={{ padding: '0px', borderRadius: '8px' }}>
      {messageHistory.map((message, index) => (
        <ListItem key={index} alignItems="flex-start" sx={{ flexDirection: message.sender === "Me" ? 'row-reverse' : 'row' }}>
          {message.sender === "Me"? <MyMessage primary={message.content} /> : <TheirMessage primary={message.content} />}
        </ListItem>
      ))}
      <div ref={endOfMessagesRef} />
    </List>
  );
}

export default MessageListComponent;
